<template>
  <div class="header">
    <div id="head_t">
      <div class="logo_w" @click="goAnchor('home')"></div>
      <div class="menu_w" @click="showMenu()"></div>
    </div>
    <!-- <div id="head_s" :class="isScroll? 'active':''">
      <div class="logo_b"></div>
      <div class="menu_r" @click="showMenu()"></div>
    </div> -->
    <!-- <div id="go_top" :class="isScroll ? 'active' : ''" @click="goTop()"></div> -->
    <!-- <div id="menu" :class="isMenu? 'active':''"></div> -->
    <transition name="slide-fade">
      <!-- <p v-if="isMenu">hello</p> -->
      <div id="menu" class="menuActive" v-show="isMenu" @click="showMenu()" :style="{background: routeFn }">
        <div class="menu-lists" style="position: relative;padding-top: 70px;">
          <div style="position: absolute;top: 33px;right: 33px;font-size: 25px;border-bottom: 0; width: 24px;">
          <img src="@/assets/img/mobile/close.png" style="width: 100%"/></div>
          <div
            class="menu-item"
            v-for="(item, index) in navMenu"
            :key="index"
            @click="goAnchor(item.value)"
            :class="[{ active: scrollSec === item.value && isSelect, itemTop: itemTop(index) }]"
          >
            {{ item.title }}
          </div>

          <div class="lang-changes" v-show="isMenu">
            <div class="lang-items">
              <div class="image" v-for="item of languageData" :key="item.id" @click="langChange(item.name)"><img :src='`https://gfgadmin.dr5kr.live/td/api/photo/${item.mapSrc}`'></div>
            </div>
            <!-- <div class="lang-items">
              <div class="image" v-for="item of languageData.slice(5, 10)" :key="item.id" @click="langChange(item.name)"><img :src='`https://gfgadmin.dr5kr.live/td/api/photo/${item.mapSrc}`'></div>
            </div> -->
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    parseVal: {
      type: Array,
      default: () => []
    },
    languageData: {
      type: Array,
      default: () => []
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.routeVal = this.$route.path
  },
  data() {
    return {
      routeVal: '',
      isScroll: false,
      isMenu: false,
      scrollSec: 'home',
      scrollTop: 0,
      navMenu: [
        {
          title: 'HOME',
          value: 'home',
        },
        {
          title: this.filterFn('filmText', 'title'),
          value: 'videoContainer',
        },
        {
          title: this.filterFn('popularGameText', 'title'),
          value: 'popularGames',
        },
        {
          title: this.filterFn('gamePresentationText', 'title'),
          value: 'games',
        },
        {
          title: this.filterFn('aboutUsText', 'title'),
          value: 'aboutUs',
        },
        {
          title: this.filterFn('officialCertificationText', 'title'),
          value: 'qw',
        },
        {
          title: this.filterFn('partnersText', 'title'),
          value: 'about',
        },
        {
          title: this.filterFn('contactUsText', 'title'),
          value: 'vision',
        },
      ],
    }
  },
  computed: {
    routeFn: function () {
      // return this.$route.path !== '/home' ? 'rgba(0, 0, 0, 0.85)' : ''
      return 'rgba(0, 0, 0, 0.85)'
    }
  },
  components: {},
  methods: {
    itemTop(index) {
      return index === 0 && this.routeVal !== '/home'
    },
    filterFn(val, key) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.find(ele => ele.key1 === localStorage.getItem("LANG_NAME"))
      return item ? item[key] : ''
    },
    langChange(value) {
      this.$i18n.locale = value;
      localStorage.setItem("LANG_NAME", value);
      console.log(localStorage.getItem("LANG_NAME"), "获取缓存成功");
      
      // this.isShowLang = false
      this.$router.go(0);
    },
    scrollHandle() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop // 获取页面滚动高度
      // console.log(this.scrollTop);
      if (this.scrollTop > 0) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
      if (this.scrollTop < 545) {
        this.scrollSec = 'home'
      } else if (this.scrollTop <= 800 && this.scrollTop > 277) {
        this.scrollSec = 'videoContainer'
      }else if (this.scrollTop <= 1277 && this.scrollTop > 820) {
        this.scrollSec = 'popularGames'
      } else if (this.scrollTop <= 2795 && this.scrollTop > 2209) {
        this.scrollSec = 'games'
      } else if (this.scrollTop <= 3530 && this.scrollTop > 2968) {
        this.scrollSec = 'aboutUs'
      } else if (this.scrollTop <= 4339 && this.scrollTop > 3665) {
        this.scrollSec = 'qw'
        // 5007
      } else if (this.scrollTop <= 5720 && this.scrollTop > 4445) {
        this.scrollSec = 'about'
      } else if (this.scrollTop > 5720) {
        this.scrollSec = 'vision'
      }
    },
    goTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    preventTouchMove(e) {
      e.preventDefault();
    },
    showMenu() {
      this.isMenu = !this.isMenu
      if (this.isMenu) {
        document.body.style.overflowY = 'hidden';  
      } else {
        document.body.style.removeProperty('overflow-y'); 
      }
    },
    goAnchor(id) {
      this.$router.push({ path: '/home' })
      var anchor = document.getElementById(id)
      console.log(anchor, id, 'anchor')
      if (anchor) {
        const head_t = document.querySelector('#head_t');
        const rect = anchor.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let offset = rect.top + scrollTop - head_t.offsetHeight;
        // 特殊处理
        if (id === 'games') {
          offset = offset - 115
        }
        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }
      document.body.style.removeProperty('overflow-y'); 
    },
  },
  mounted() {
    if (this.isSelect) {
      window.addEventListener('scroll', this.scrollHandle, true) //绑定页面滚动事件
    }
  },
  beforeDestroy() {
    if (this.isSelect) {
      window.removeEventListener('scroll', this.scrollHandle) //移除滚动条监听
      document.body.style.removeProperty('overflow-y'); 
    }
  },
}
</script>

<style lang="less" scoped>
.header {
  width: 750px;
  height: 0px;
  color: #fff;
  // padding-top: 170px;
  font-size: 36px;
}
#head_t {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 750px;
  height: 140px;
  top: 0px;
  left: 0;
  // margin: 30px 20px;
  box-sizing: border-box;
  // transition: all .4s ease;
  // border-radius: 12px;
  padding: 0 40px 0;
  // box-shadow: 0 13px 15px 0 rgba(0,0,0,.1);
  // background: rgba(0, 0, 0, 0.8);
  border-bottom: 12px solid;
  border-image: url('~@/assets/img/mobile/bar.png') 5 round;
  // border-image: linear-gradient(90deg, rgb(153, 96, 255) 0%, #5f7eff 100%) 2 2 2
  //   2;
  background: url('~@/assets/img/mobile/bg.png');
  z-index: 101;
  &.active {
    top: -200px;
  }
  .logo_w {
    width: 270px;
    height: 69px;
    background: url(../assets/img/logo_w.png) center no-repeat;
    background-size: contain;
  }
  .menu_w {
    width: 67px;
    height: 51px;
    background: url(../assets/img/menu_w.png) center no-repeat;
    background-size: contain;
  }
}
#head_s {
  display: flex;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 710px;
  height: 120px;
  top: -200px;
  left: 0;
  margin: 30px 20px;
  box-sizing: border-box;
  transition: all 0.4s ease;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 13px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  &.active {
    top: 0;
  }
  .logo_b {
    width: 162px;
    height: 60px;
    background: url(../assets/img/logo_b.png) center no-repeat;
    background-size: contain;
  }
  .menu_r {
    width: 60px;
    height: 60px;
    background: url(../assets/img/menu_r.png) center no-repeat;
    background-size: contain;
  }
}
#go_top {
  display: block;
  position: fixed;
  z-index: 999;
  width: 120px;
  height: 88px;
  background: url(../assets/img/gotop.png) center no-repeat;
  background-size: contain;
  right: -120px;
  bottom: 280px;
  border-radius: 50px 0 0 50px;
  box-sizing: border-box;
  padding: 7px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.8s ease;
  &.active {
    right: 0;
  }
}
#menu {
  display: flex;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
  // padding: 0vw 40vw 0 0;
  transition: all 1s ease;
  align-items: center;
  flex-direction: column;
  // border-radius: 100%;
  // &.active {
  //   visibility: visible;
  // }
 .menu-lists {
    // background: rgba(0, 0, 0, 0.85);
    width: 100vw;
    height: 100%;
    padding: 161px 4vw;
    z-index: 999;
    .menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 522px;
      height: 89px;
      margin: 29px auto;
      border-bottom: 1px solid #6A6868;
      // margin: 20px 0 0 0;
      text-align: center;
      font-size: 24px;
      font-family: 'fontText';
      font-weight: 400;
      color: #FFFFFF;
      text-transform:uppercase;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .first {
    justify-content: space-between;
    height: 60px;
    margin-bottom: 100px;
  }
  .logo_b {
    width: 162px;
    height: 60px;
    background: url(../assets/img/logo_b.png) center no-repeat;
    background-size: contain;
  }
  .menu_r {
    width: 60px;
    height: 60px;
    background: url(../assets/img/menu_r.png) center no-repeat;
    background-size: contain;
  }
}


.menuActive {
  .active {
    height: 89px;
    background: linear-gradient(90deg, #7E429C, #501B97);
    border-radius: 44px;
    margin-top: -20px!important;
    // background: url('~@/assets/img/mobile/active.png') center no-repeat;
    // background-image: linear-gradient(90deg, #aa57ff 0%, #5882ff 100%);
    // border-radius: 40px;
  }
  .itemTop {
    margin-top: -20px!important;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(20px);
  transform-origin: right top;
  opacity: 0;
}

.lang-changes {
  // margin-top: 143px;
  display: flex;
  flex-direction: column;
  position: relative;
    z-index: 999;
    
}
.lang-items {
  display: flex;
  border-bottom: none!important;
  margin-bottom: 50px;
  height: 180px;
  overflow-y: auto;
  text-align: center;
  flex-wrap: wrap; 
  .image {
    width: 20%;
    margin-bottom: 50px;
  }
  img {
    display: inline-block;
    width: 58px;
    // width: 45px;
    // height: 45px;
    border-radius: 50%;
  }
}
</style>
